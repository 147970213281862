import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>The page you are looking for does not exist</p>
  </>
)

export default NotFoundPage
